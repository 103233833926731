import React from 'react';
// import { silentAuth } from "./src/dashboard/utils/auth"
import '@fortawesome/fontawesome-svg-core/styles.css';
// class SessionCheck extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       loading: true,
//     }
//   }

//   handleCheckSession = () => {
//     this.setState({ loading: false })
//   }

//   componentDidMount() {
//     silentAuth(this.handleCheckSession)
//   }

//   render() {
//     return (
//       this.state.loading === false && (
//         <React.Fragment>{this.props.children}</React.Fragment>
//       )
//     )
//   }
// }

// export const wrapRootElement = ({ element }) => {
//   return <SessionCheck>{element}</SessionCheck>
// }
// const logo = require('./src/assets/images/company-logo/true-icon.svg');

// gatsby-plugin-offline

// exports.onServiceWorkerUpdateFound = () => {
//   const answer = window.confirm(
//     `This application has been updated. ` +
//       `Reload to display the latest version?`
//   )

//   if (answer === true) {
//     window.location.reload()
//   }
// }

// let body;
// let loaderElement;

// exports.onClientEntry = () => {
//   body = document.getElementsByTagName('body')[0];

//   body.style.overflow = 'hidden';

//   loaderElement = document.createElement('div');
//   loaderElement.id = '___loader';

//   const imgElement = document.createElement('img');
//   imgElement.src = logo;

//   loaderElement.appendChild(imgElement);

//   body.appendChild(loaderElement);
// };

export const onClientEntry = () => {
  const body = document.getElementsByTagName('body')[0];
  const theme = window.localStorage.getItem('theme') || 'light';
  body.setAttribute('data-theme', theme);
};

// exports.onInitialClientRender = () => {
//   setTimeout(() => {
//     body.removeChild(loaderElement);
//     body.style.overflow = 'visible';
//   }, 2000);
// };
